import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../Particle';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import BannerWrapper, { BannerObject } from './bannerSection.style';

import BannerObject1 from 'common/src/assets/image/saas/banner/ooda4.png';

const Highlighted = styled.span`
  background-color: rgb(51, 51, 51);
  background-color: rgb(245, 190, 71);
  color: #fff;
  padding: 6px 3px 7px 0;
  margin: -6px -3px -7px 0;
`;

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  outlineBtnStyle,
  bannerImageStyle,
}) => {
  const ButtonGroup = () => (
    <Fragment>
      <AnchorLink href="#feature_section" offset={70}>
        <Button
          className="outlined"
          title="EXPLORE"
          variant="outlined"
          {...outlineBtnStyle}
        />
      </AnchorLink>
    </Fragment>
  );
  return (
    <BannerWrapper id="banner_section">
      {/* <Particles /> */}
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project " {...discountText} />
            </DiscountLabel> */}
            <FeatureBlock
              title={
                <Heading
                  content={
                    <div>Accelerating the delivery of business value from practical and responsible data and AI</div>
                  }
                  {...title}
                />
              }
              description={
                <div>
                  <Text
                    content="At Europa Labs our mission is to build business value from the data that flows through an organisation. We partner with our clients to identify the highest value use cases, create the appetite and capability for change and deliver working systems that thoughtfully combine cutting edge techniques with robust implementations."
                    {...description}
                  />
                </div>
              }
              button={<ButtonGroup />}
            />
          </Box>
        </Box>
      </Container>
      <BannerObject>
        <div className="objectWrapper">
          <Image src={BannerObject1} alt="BannerObject1" />
          {/* <div className="dashboardWrapper">
            <Image src={BannerObject2} alt="BannerObject2" />
          </div> */}
        </div>
      </BannerObject>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, '70%', '50%', '45%'],
  },
  title: {
    fontSize: ['22px', '34px', '30px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '120px', '120px', '156px'],
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: 'primaryWithBg',
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#5167db',
    ml: '18px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#eb4d4b',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    fontWeight: 700,
  },
  discountText: {
    fontSize: ['13px', '14px'],
    color: '#0f2137',
    mb: 0,
    as: 'span',
    fontWeight: 500,
  },
};

export default BannerSection;
